import React, { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";

import { Footer, Blog, Possibility, Features, WhatGPT3, Header } from './containers';
import { Cta, Navbar } from './components';
import './App.css'

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className='App' data-aos="zoom-in" data-aos-duration="2000">
        <div className='gradient_bg' >
          <Navbar />
          <Header />
        </div>
        <div data-aos="fade-up" data-aos-duration="2000">
          <WhatGPT3 />
        </div>

        <div className='blue' data-aos="fade-right" data-aos-duration="2000">
          <Features />
        </div>

        <div data-aos="fade-left" data-aos-duration="2000">
          <Possibility />
        </div>

        <div data-aos="fade-up" data-aos-duration="2000">
          <Cta />
        </div>

        <div data-aos="fade-up" data-aos-duration="2000">
          <Blog />
        </div>

        <div data-aos="fade-up" data-aos-duration="2000">
          <Footer />
        </div>
        <div className='gpt3_footer-copyright'>
          <p>© Copyright Siprocom-Trans 2024</p>
        </div>
    </div>
  )
}

export default App