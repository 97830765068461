import React from 'react';
import { Feature } from '../../components';
import './whatGPT3.css';

const WhatGPT3 = () => {
  return (
    <div className='gpt3_whatgpt3 section_margin' id='whpt3'>
        <div className='gpt_whatgpt3-feature'>
          <Feature  title={"Qui sommes-nous?"} text = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil officia facere officiis voluptatibus? Ipsa suscipit cumque, nisi ut sunt harum sint recusandae distinctio eius dolor. Explicabo et repellendus quos vitae." />
        </div>
        <div className='gpt3_whatgpt3-heading'>
          <h1 className='gradient_text'>Des possibilités au delà de votre imagination... </h1>
        </div>
        <div className='gpt3_whatgpt3-container'>
          <Feature title={"Base de Connaissances"} text = "SPECIALISTE DU TRANSIT ET DE LA LOGISTIQUE DEPUIS 2001"/>
        </div>
    </div>
  )
}

export default WhatGPT3