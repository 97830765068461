
import React from 'react';
import './possibility.css';
import possibility from '../../assets/port.jpg'

const Possibility = () => {
  return (
    <div className='gpt3_possibility section_padding' id='possibility'>
      <div className='gpt3_possibility-image'>
        <img src = {possibility} alt="possibility" />
      </div>
      <div className='gpt3_possibility-content'>
        <h1 className='gradient_text_2'>COMMISSIONNAIRE AGREE EN DOUANE</h1>
        <p>Transitaire expert du commerce international, SIPROCOM-TRANS est spécialisé sur les flux Europe-Afrique et Asie-Afrique pour le    transport de marchandises, et peut également vous accompagner sur tout autre type de destination, quel que soit le pays de départ.Nos équipes spécialisées vous accompagnent dans l’organisation de vos flux internationaux de marchandises.
          SIPROCOM-TRANS est un représentant en douane expérimenté qui maîtrise également parfaitement le transport maritime, le transport aérien, la logistique de groupage (groupage maritime et aérien) ainsi que les containers personnalisés. Projet cargo, door to door, transport express et transport multimodal, depuis ****, SIPROCOM-TRANS organise le transport international grâce à des équipes expertes, dédiées et proches de vous.</p>
      </div>
      
    </div>
  );
}

export default Possibility;