import React from 'react';
import './article.css';

const Article = ({ imgUrl, title, text }) => {
  return (
    <div className='gpt3_blog-container_article'>
        <div className='gpt3_blog-container_article-image'>
          <img src = {imgUrl} alt="blog" />
        </div>
        <div className='gpt3_blog-container_article-content'>
          <div>
            <h3>{title}</h3>
            <p>{text}</p>
          </div>
        </div>
    </div>
  )
}

export default Article