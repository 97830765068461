import React from 'react';
import './blog.css';
import { Article } from '../../components';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';

const Data = [
  {
    title: "UN SERVICE LOGISTIQUE COMPLET : SIPROCOM-TRANS, LE CHOIX D'UN SPECIALISTE ",
    text: "Depuis plus de 20 ans, SIPROCOM-TRANS opère à l’import comme à l’export sur tous les continents, grâce à son vaste réseau d’agents de transit et ses filiales internationales. Notre réseau de transitaires est implanté notamment en Afrique de l’Ouest, en Afrique centrale et en Europe, au plus près des hubs logistiques, aéroports, ports et entrepôts. Cette proximité avec les transporteurs nous permet de négocier au mieux chaque aspect des opérations logistiques menées pour nos clients."
  }
]


const Data1 = [
  {
    title: 'TRANSIT-DOUANE',
    text: "SIPROCOM-TRANS peut réaliser toutes les opérations liées au transit sous douane : émission et suivi des documents réglementaires tels que le connaissement, la liste de colisage, dédouanement … Un gain de temps et d’efficacité pour l’ensemble de vos opérations de transport et votre gestion logistique!"
  }
]

const Data2 = [
  {
    title: 'CONSIGNATION',
    text: "Notre service de consignation à SIPROCOM-TRANS assure une relation permanente entre l'armateur, le chargeur, le navire et les acteurs portuaires. Avec une équipe compétente, efficace et disponible 24h sur 24 et 365 jours par an, pour le bon déroulement d’une escale rapide. "
  }
]

const Data3 = [
  {
    title: 'ENTREPOSAGE',
    text: "Nos équipes sont formées et sensibilisées à la manipulation de marchandises de tous formats. En Afrique comme dans le monde entier, nous pouvons ainsi procéder à l’empotage et à l’optimisation de vos cargaisons avec une maîtrise complète de la protection de vos marchandises durant les trajets, les transbordements et les éventuelles phases de stockage."
  }
]

const Data4 = [
  {
    title: 'COMMERCE GENERALE (Export-Import)',
    text: "Notre équipe d'experts négociateurs obtient pour vous de meilleurs tarifs grâce à notre pouvoir d'achat en volume qui réduit les coûts. Pour de nombreux produits nous avons nos propres fournisseurs et pour d'autres nous faisons du sourcing."
  }
]



const Blog = () => {
  return (
    <div className='gpt3_blog section_padding' id='blog'>
      <div className='gpt3_blog-heading'>
      <h1 className='gradient_text_2'>
          Nous travaillons dans plusieurs domaines, Nous bloguons à ce sujet
        </h1>
      </div>
      <div className='gpt3_blog-container'>
        <div className='gpt3_blog-container_groupA'>
        {Data.map((item) => (
          <Article imgUrl={blog01} title={item.title} text={item.text} />
        ))}
        </div>
        <div className='gpt3_blog-container_groupB'>
        {Data1.map((item) => (
          <Article imgUrl={blog02} title={item.title} text={item.text} />
        ))}
        {Data2.map((item) => (
          <Article imgUrl={blog03} title={item.title} text={item.text}  />
        ))}
        {Data3.map((item) => (
          <Article imgUrl={blog04} title={item.title} text={item.text}  />
        ))}
        {Data4.map((item) => (
          <Article imgUrl={blog05} title={item.title} text={item.text}  />
        ))}
        </div>
      </div>
     </div>
  )
}

export default Blog