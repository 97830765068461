import React from 'react';
import './header.css';
import entreprise from '../../assets/accueil.jpeg'

const Header = () => {
  return (
    <div className='gpt3_header section_padding' id='home'>
      <div className='gpt3_header-content'>
          <h1 className='gradient_text'>
             Commissionnaire agrée en douanes
          </h1>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia dicta suscipit et quidem. Inventore excepturi iusto ullam, praesentium id odio optio autem minima, exercitationem deserunt expedita cum eaque vitae vel!</p>
          <div className='gpt3_header-content_input'>
            <button type='button' id='contacts'>
                <a href='tel:+228 90 07 39 49'>Nous Contacter</a>
            </button>
          </div>
      </div>
      <div className='gpt3_header-image'>
          <img src={entreprise} alt='entreprise' />
      </div>
    </div>
  )
}

export default Header