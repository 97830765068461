import React from 'react';
import './footer.css';
import logo from '../../assets/logo.png'

const Footer = () => {
  return (
    <div className='gpt3_footer section_padding' id='footer'>
      <div className='gpt3_footer-heading'>
        <h1 className='gradient_text'>
          Avec SIPROCOM-TRANS, votre satisfaction est assurée.
        </h1>
      </div>

      <div className='gpt3_footer-links'>
        <div className='gpt3_footer-links_logo'>
          <div className='gpt3_footer-links_logo-image' style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <img src={logo} alt="logo" style={{
                  height: '80px',
                  width: '80px',
                }}/>
            <p style={{
              fontWeight: 'bold',
              fontSize: '15px',
              marginBottom: '0',
              marginLeft: '1rem'
            }}>SIPROCOM-TRANS</p>
          </div>
          {/* <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti nisi velit facilis libero quia numquam ex accusamus labore dolore nesciunt, quo dolorum. Veniam consequatur quae officiis voluptatem possimus obcaecati assumenda!</p> */}
        </div>
        <div className='gpt3_footer-links_div'>
          <h4>Liens utiles</h4>
          <p><a href="#whpt3">Qui sommes nous ?</a></p>
        </div>
        <div className='gpt3_footer-links_div'>
          <h4>Nos services</h4>
          <p>Transit-douane</p>
          <p>Consignation</p>
          <p>Entrepôsage</p>
          <p>Commerce générale export-import</p>
        </div>
        <div className='gpt3_footer-links_div'>
          <h4>Nous contacter</h4>
          <p>Tel: 22 21 17 73</p>
          <p><a href='tel:+228 90 07 39 49'>Mobile 1: +228 90 07 39 49</a></p>
          <p><a href='tel:+228 99 03 50 90'>Mobile 2: +228 99 03 50 90</a></p>
          <p><a href='mailto:siprocomtrans@yahoo.com'>Email: siprocomtrans@yahoo.com</a></p>
          <p>BP: 61528</p>
        </div>
      </div>
    </div>
  )
}

export default Footer