import React from 'react';
import { Feature } from '../../components';
import './features.css';
import activités from '../../assets/port1.jpg';

const featuresData = [
  {
    title: 'TRANSIT-DOUANE',
    text: "Représentant en douane, nous opérons des formalités douanières dans tout les ports et aéroports africains à l'import comme à l'export. Nous disposons au Togo de notre propre service douane doté de déclarants en douane expérimentés et rigoureux."
  },
  {
    title: 'CONSIGNATION',
    text: "SIPROCOM-TRANS se charge de toutes le sopérations de consignation et de transport maritime. En tant que consignataire, nous réalisons toutes les opérations matérielles de chargements et de déchargements de votre marchandise en vue d'accélérer la livraison "
  },
  {
    title: 'ENTREPOSAGE',
    text: "Vous avez besoin de services en logistique? Outre la réalisation de nos propres groupages, nous proposons, en nos entrepôts du Togo, des services d'empotage afin de vous accompagner au mieux dans vos expéditions."
  },
  {
    title: 'COMMERCE GENERALE (Export-Import)',
    text: "Nous sommes tout autant qualifié dans le domaine de l'import-export. Depuis nos bureaux au Togo, nous aidons les plus grands importateurs , commerçants et entreprises à importer et exporter des produits au meilleurs prix grâce au savoir faire de nos acheteurs en sourcingat l'appui de nos partenaires internationaux."
  }
]

const Features = () => {
  return (
    <div className='gpt3_features section_padding' id='features'>
      <div className='gpt3_features-heading'>
        <h1 className='gradient_text'>Nos Activités</h1>
        <img src = {activités} alt="activités" />
      </div>
      <div className='gpt3_features-container'>
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}

export default Features